import React, { useState, useEffect, useRef, useContext } from "react";

import * as Yup from "yup";
import { Formik, FieldArray, Form, Field, useFormik, FormikProvider } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import CircularProgress from "@material-ui/core/CircularProgress";
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { MenuItem, Select } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},

	extraAttr: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
}));

const ContactSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required"),
	number: Yup.string().min(8, "Too Short!").max(50, "Too Long!"),
	email: Yup.string().email("Invalid email"),
});

const ContactModal = ({ open, onClose, contactId, initialValues, onSave, addAfterSalesOnSave = false, addCustomFieldsOnSave = false,  }) => {
	const classes = useStyles();
	const isMounted = useRef(true);
	const { user } = useContext(AuthContext);

	const initialState = {
		name: "",
		number: "",
		email: "",
		extraInfo: []
	};

	const [contact, setContact] = useState(initialState);

	const formik = useFormik({
		initialValues: contact,
		enableReinitialize: true,
		validationSchema: ContactSchema,
		onSubmit: (values, actions) => {
			setTimeout(() => {
				handleSaveContact(values);
				actions.setSubmitting(false);
			}, 400);
		}
	});

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	useEffect(() => {
		const fetchContact = async () => {
			if (initialValues) {
				setContact(prevState => {
					return { ...prevState, ...initialValues };
				});
			}

			if (!contactId) return;

			try {
				const { data } = await api.get(`/contacts/${contactId}`);
				if (isMounted.current) {
					setContact(data);
				}
			} catch (err) {
				toastError(err);
			}
		};

		fetchContact();
	}, [contactId, open, initialValues]);

	const handleClose = () => {
		onClose();
		setContact(initialState);
	};

	const handleImage = (file, index)=> {
		if(!file) {
			return;
		}
	
		const reader = new FileReader();
		reader.onload = () => {
			const extraInfo = [...formik.values.extraInfo];
			extraInfo[index].value = file.name;
			extraInfo[index].image = reader.result;
			extraInfo[index].mediaPath = undefined;

			formik.setValues({...formik.values, extraInfo});
		}

		reader.readAsDataURL(file);
	}

	const handleAdcustomFields = async () => {
		try {
			const { data } = await api.post(`/contacts/${contactId}/custom-fields`);
			setContact(data);
		} catch (error) {
			
		}
	}

	const handleAfterSales = async () => {
		try {
			let _contactId = contactId;

			if (_contactId) {
				await api.put(`/contacts/${contactId}`, formik.values);
			} else {
				const { data } = await api.post("/contacts", formik.values);
				if (onSave) {
					onSave(data);
				}
			}

			if(_contactId) {
				await api.post(`/aftersales`, {
					contact: {
						id: _contactId,
						name: formik.values.name,
						number: formik.values.number,
						email: formik.values.email,
					},
					details: formik.values.extraInfo
				});
			}

			toast('Contato enviado para pós-venda')
		} catch (error) {
			
		}
	}

	const handleSaveContact = async () => {
		try {
			let _contactId = contactId;

			if (_contactId) {
				await api.put(`/contacts/${contactId}`, formik.values);
				handleClose();
				toast.success(i18n.t("contactModal.success"));
				return;
			}

			const { data } = await api.post("/contacts", formik.values);
			_contactId = data.id;

			if (onSave) {
				onSave(data);
			}

			await afterSave();

			handleClose();
			toast.success(i18n.t("contactModal.success"));
		} catch (err) {
			toastError(err);
			if (err.response?.data?.error === "ERR_DUPLICATED_CONTACT") {
				// const { data } = await api.get(`/contacts?searchParam=${formik.values.number}`);
				// if (data.contacts?.length) {
				// 	const _contact = data.contacts[0];
				// 	_contact.sellername ? toastError(`${i18n.t(`backendErrors.${err.response?.data?.error}`)} Atendente: ${_contact.sellername}`) : toastError(err);
				// }
				await afterSave();
			} 
			// else {
			// 	toastError(err);
			// }
		}
	};

	const afterSave = async () => {
		const { data } = await api.get(`/contacts?searchParam=${formik.values.number}`);
		const _contact = data.contacts[0];
		const _contactId = _contact.id;

		if (addAfterSalesOnSave && _contactId) {
			await api.post(`/aftersales`, { contactId: _contactId });
		}

		if (addCustomFieldsOnSave && _contactId) {
			await api.post(`/contacts/${_contactId}/custom-fields`);
		}

		if (addAfterSalesOnSave || addCustomFieldsOnSave) {
			toast.success(i18n.t("Pós-Venda adicionado com sucesso."));
			handleClose();
		}
	}

	const isImage = (value) => {
		return value.toLocaleLowerCase().trim().startsWith('img')
	}

	const isSelect = (value) => {
		return value.match(/CMB\s{1,}(.*)\[(.*)\]/i);
	}

	const cmbOptions = (value) => {
		const m =  value.match(/CMB\s{1,}(.*)\[(.*)\]/i);
		return m[2].split('|');
	}

	const cmbLabel = (value) => {
		const m =  value.match(/CMB\s{1,}(.*)\[(.*)\]/i);
		return m[1];
	}

	return (
		<div className={classes.root}>
			<Dialog open={open} onClose={handleClose} maxWidth="lg" scroll="paper">
				<DialogTitle id="form-dialog-title">
					{contactId
						? `${i18n.t("contactModal.title.edit")}`
						: `${i18n.t("contactModal.title.add")}`}
				</DialogTitle>
				<FormikProvider value={formik}>
				<form onSubmit={formik.handleSubmit}>
					<DialogContent dividers>
						<Typography variant="subtitle1" gutterBottom>
							{i18n.t("contactModal.form.mainInfo")}
						</Typography>
						<TextField
							label={i18n.t("contactModal.form.name")}
							name="name"
							autoFocus
							variant="outlined"
							margin="dense"
							value={formik.values.name}
							onChange={formik.handleChange}
							className={classes.textField}
						/>
						<TextField
							label={i18n.t("contactModal.form.number")}
							name="number"
							placeholder="5541998608485"
							variant="outlined"
							margin="dense"
							value={formik.values.number}
							onChange={formik.handleChange}
						/>
						<div>
							<TextField
								label={i18n.t("contactModal.form.email")}
								name="email"
								placeholder="Email address"
								fullWidth
								margin="dense"
								variant="outlined"
								value={formik.values.email}
								onChange={formik.handleChange}
							/>
						</div>
						<Typography
							style={{ marginBottom: 8, marginTop: 12 }}
							variant="subtitle1"
						>
							{i18n.t("contactModal.form.whatsapp")} {contact?.whatsapp ? contact?.whatsapp.name : ""}
						</Typography>
						<Typography
							style={{ marginBottom: 8, marginTop: 12 }}
							variant="subtitle1"
						>
							{i18n.t("contactModal.form.extraInfo")}
						</Typography>
						<FieldArray name="extraInfo">
							{({remove, push}) =>
								<>
									{formik.values.extraInfo &&
										formik.values.extraInfo.length > 0 &&
										formik.values.extraInfo.map((info, index) => (
											<div
												className={classes.extraAttr}
												key={`${index}-info`}
											>
												{!isSelect(formik.values.extraInfo[index].name) && 
													<TextField
														label={i18n.t("contactModal.form.extraName")}
														name={`extraInfo[${index}].name`}
														variant="outlined"
														margin="dense"
														className={classes.textField}
														value={formik.values.extraInfo[index].name}
														onChange={formik.handleChange}
													/>
												}

												{isSelect(formik.values.extraInfo[index].name) && 
													<TextField
														label={i18n.t("contactModal.form.extraName")}
														name={`extraInfo[${index}].name`}
														variant="outlined"
														margin="dense"
														disabled={true}
														className={classes.textField}
														value={cmbLabel(formik.values.extraInfo[index].name)}
														onChange={formik.handleChange}
													/>
												}

												{!isImage(formik.values.extraInfo[index].name) && isSelect(formik.values.extraInfo[index].name) && 
													<Select
														label={i18n.t("contactModal.form.extraValue")}
														name={`extraInfo[${index}].value`}
														variant="outlined"
														margin="dense"
														className={classes.textField}
														value={formik.values.extraInfo[index].value}
														onChange={formik.handleChange}
													>
														{cmbOptions(formik.values.extraInfo[index].name).map((item, index) => (
															<MenuItem key={index} value={item}>{item}</MenuItem>
														))}
													</Select>
												}

												{!isImage(formik.values.extraInfo[index].name) && !isSelect(formik.values.extraInfo[index].name) && 
													<TextField
														label={i18n.t("contactModal.form.extraValue")}
														name={`extraInfo[${index}].value`}
														variant="outlined"
														margin="dense"
														className={classes.textField}
														value={formik.values.extraInfo[index].value}
														onChange={formik.handleChange}
												/>}

												{isImage(formik.values.extraInfo[index].name) && formik.values.extraInfo[index].mediaPath && (
													<a href={formik.values.extraInfo[index].mediaPath} target="_blank">{formik.values.extraInfo[index].value}</a>
												)}

												{isImage(formik.values.extraInfo[index].name) && formik.values.extraInfo[index].value && ! formik.values.extraInfo[index].mediaPath &&  (
													<TextField 
														value={formik.values.extraInfo[index].value}
														label="Imagem"
														variant="outlined"
														margin="dense"
														className={classes.textField}
														disabled
													/> 
												)}

												{isImage(formik.values.extraInfo[index].name) && (
													<IconButton size="small" component="label">
														<input type="file" hidden onChange={(ev) => handleImage(ev.target.files[0], index)} />
														<InsertPhotoIcon />
													</IconButton>
												)}

												<IconButton
													size="small"
													onClick={() => remove(index)}
												>
													<DeleteOutlineIcon />
												</IconButton>
											</div>
										))}
									<div className={classes.extraAttr}>
										<Button
											style={{ flex: 1, marginTop: 8 }}
											variant="outlined"
											color="primary"
											onClick={() => push({name: '', value: ''})}
										>
											{`+ ${i18n.t("contactModal.buttons.addExtraInfo")}`}
										</Button>
									</div>
								</>
							}
						</FieldArray>
					</DialogContent>
					<DialogActions>
						{contactId && user.allowAfterSales && <Button
							onClick={handleAfterSales}
							color="secondary"
							disabled={formik.isSubmitting}
							variant="outlined"
						>
							{i18n.t("Enviar pós-venda")}
						</Button>}
						{contactId && <Button
							onClick={handleAdcustomFields}
							color="primary"
							disabled={formik.isSubmitting}
							variant="outlined"
						>
							{i18n.t("Add Campos Customizados")}
						</Button>}
						<Button
							onClick={handleClose}
							color="secondary"
							disabled={formik.isSubmitting}
							variant="outlined"
						>
							{i18n.t("contactModal.buttons.cancel")}
						</Button>
						<Button
							color="primary"
							type="submit"
							disabled={formik.isSubmitting}
							variant="contained"
							className={classes.btnWrapper}
							/* onClick={handleSaveContact} */
						>	
							{contactId
								? `${i18n.t("contactModal.buttons.okEdit")}`
								: `${i18n.t("contactModal.buttons.okAdd")}`}
							{formik.isSubmitting && (
								<CircularProgress
									size={24}
									className={classes.buttonProgress}
								/>
							)}
						</Button>
					</DialogActions>
				</form>
				</FormikProvider>
				{/*<Formik
					initialValues={contact}
					enableReinitialize={true}
					validationSchema={ContactSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveContact(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ values, errors, touched, isSubmitting }) => (
						<Form>
							<DialogContent dividers>
								<Typography variant="subtitle1" gutterBottom>
									{i18n.t("contactModal.form.mainInfo")}
								</Typography>
								<Field
									as={TextField}
									label={i18n.t("contactModal.form.name")}
									name="name"
									autoFocus
									error={touched.name && Boolean(errors.name)}
									helperText={touched.name && errors.name}
									variant="outlined"
									margin="dense"
									className={classes.textField}
								/>
								<Field
									as={TextField}
									label={i18n.t("contactModal.form.number")}
									name="number"
									error={touched.number && Boolean(errors.number)}
									helperText={touched.number && errors.number}
									placeholder="5541998608485"
									variant="outlined"
									margin="dense"
								/>
								<div>
									<Field
										as={TextField}
										label={i18n.t("contactModal.form.email")}
										name="email"
										error={touched.email && Boolean(errors.email)}
										helperText={touched.email && errors.email}
										placeholder="Email address"
										fullWidth
										margin="dense"
										variant="outlined"
									/>
								</div>
								<Typography
									style={{ marginBottom: 8, marginTop: 12 }}
									variant="subtitle1"
								>
									{i18n.t("contactModal.form.whatsapp")} {contact?.whatsapp ? contact?.whatsapp.name : ""}
								</Typography>
								<Typography
									style={{ marginBottom: 8, marginTop: 12 }}
									variant="subtitle1"
								>
									{i18n.t("contactModal.form.extraInfo")}
								</Typography>

								<FieldArray name="extraInfo">
									{({ push, remove }) => (
										<>
											{values.extraInfo &&
												values.extraInfo.length > 0 &&
												values.extraInfo.map((info, index) => (
													<div
														className={classes.extraAttr}
														key={`${index}-info`}
													>
														<Field
															as={TextField}
															label={i18n.t("contactModal.form.extraName")}
															name={`extraInfo[${index}].name`}
															variant="outlined"
															margin="dense"
															className={classes.textField}
														/>
														
														{!isImage(values.extraInfo[index].name) && <Field
															as={TextField}
															label={i18n.t("contactModal.form.extraValue")}
															name={`extraInfo[${index}].value`}
															variant="outlined"
															margin="dense"
															className={classes.textField}
														/>}

														{isImage(values.extraInfo[index].name) && !images[index]?.name && values.extraInfo[index].mediaPath && (
															<a href={values.extraInfo[index].mediaPath} target="_blank">{values.extraInfo[index].value}</a>
														)}

														{isImage(values.extraInfo[index].name) && images[index]?.name &&  (
															<TextField 
																value={images[index]?.name}
																label="Imagem"
																variant="outlined"
																margin="dense"
																className={classes.textField}
																disabled
															/> 
														)}

														{isImage(values.extraInfo[index].name) && (<IconButton size="small" component="label">
															<input type="file" hidden onChange={(ev) => handleImage(ev.target.files[0], index)} />
															<InsertPhotoIcon />
														</IconButton>)}

														<IconButton
															size="small"
															onClick={() => remove(index)}
														>
															<DeleteOutlineIcon />
														</IconButton>
													</div>
												))}
											<div className={classes.extraAttr}>
												<Button
													style={{ flex: 1, marginTop: 8 }}
													variant="outlined"
													color="primary"
													onClick={() => push({ name: "", value: "" })}
												>
													{`+ ${i18n.t("contactModal.buttons.addExtraInfo")}`}
												</Button>
											</div>
										</>
									)}
								</FieldArray>
							</DialogContent>
							<DialogActions>
								{user.allowAfterSales && <Button
									onClick={handleAfterSales}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("Enviar pós-venda")}
								</Button>}
								<Button
									onClick={handleAdcustomFields}
									color="primary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("Add Campos Customizados")}
								</Button>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("contactModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{contactId
										? `${i18n.t("contactModal.buttons.okEdit")}`
										: `${i18n.t("contactModal.buttons.okAdd")}`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>*/}
			</Dialog>
		</div>
	);
};

export default ContactModal;
